<template>
  <div>
    <!-- Gold Sponsors -->
    <h1 class="title is-4">In partnership with</h1>
    <p class="heading is-size-7">Gold Sponsors</p>
    <div class="columns is-centered is-vcentered is-multiline is-mobile">
      <div class="column is-2-desktop is-3-tablet is-5-mobile" v-for="sponsor in sponsors">
        <img :src="`https://cdn.statically.io/img/${sponsor.logo.replace(/(https:\/\/)|-/g, '')}`" class="gold-logo" @click="launchModal(sponsor)">
      </div>
    </div>

    <p class="title has-text-grey is-6">Click on each logo to learn more</p>

    <!-- Modal -->
    <b-modal v-if="sponsorModalContent" v-model="isSponsorModalActive" :width="640" @close="onModalClose">
      <div class="card">
        <div class="card-image">
          <figure class="image is-16by9 has-background-grey">
            <iframe :src="sponsorModalContent.avp" class="has-ratio"></iframe>
          </figure>
        </div>
        <div class="card-content">
          <div class="media">
            <div class="media-left">
              <figure class="image is-128x128">
                <img :src="`https://cdn.statically.io/img/${sponsorModalContent.logo.replace(/(https:\/\/)|-/g, '')}`">
              </figure>
            </div>
            <div class="media-content has-text-left">
              <p class="title">{{sponsorModalContent.name}}</p>
              <a v-for="website in sponsorModalContent.website" class="subtitle is-6 has-text-gold" :href="website" target="_blank">{{website}}<br></a>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "SponsorsList",
  data() { return {
    isSponsorModalActive: false,
    sponsorModalContent: null,
    sponsors: [
      {
        name: "AMS Group of Companies",
        avp: "",
        website: ["https://amsgroup.ph"],
        logo: "https://i.ibb.co/tPFbGGz/ams.png"
      },
      {
        name: "Cody's Bakery MNL",
        avp: "https://drive.google.com/file/d/1hH65IgN67blgnkXGIFh2LnNFs2sT-ok1/preview",
        website: ["https://instagram.com/codysbakerymnl","https://www.facebook.com/codysbakerymnl"],
        logo: "https://i.ibb.co/q7RgnSb/cody.jpg"
      },
      {
        name: "Clorox",
        avp: "https://drive.google.com/file/d/1tSvlD5_yc7GP-RhbWqrIBlvH0Z23piO4/preview",
        website: ["https://s.lazada.com.ph/s.VAUVH"],
        logo: "https://i.ibb.co/NWFW6tP/clorox.png"
      },
      {
        name: "Pine-Sol",
        avp: "",
        website: ["https://s.lazada.com.ph/s.VAUVH"],
        logo: "https://i.ibb.co/R9qMh9m/Pine-Sol.png"
      }
    ]
  }},
  methods: {
    launchModal(sponsor) {
      this.sponsorModalContent = sponsor
      this.isSponsorModalActive = true
    },
    onModalClose() {
      this.sponsorModalContent = null
    }
  }
}
</script>

<style scoped>
.gold-logo {
  cursor: pointer;
}
</style>