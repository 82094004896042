<template>
  <div class="columns is-variable is-2 is-multiline is-centered is-vcentered is-mobile">
    <div v-for="firm in firms" class="column is-1-desktop is-2-tablet is-3-mobile has-text-centered">
      <img :src="`https://cdn.statically.io/img/${firm.logo.replace(/(https:\/\/)|-/g, '')}`" class="logo">
      <p class="firm-name">{{firm.name}}</p>
    </div>
  </div>
</template>

<script>
import {supabase} from "../supabaseClient";
export default {
  name: "FirmLogoWall",
  data() { return {
    firms: []
  }},
  mounted: async function (){
    let query = supabase
      .from('employers')
      .select('id, name, logo')
      .order('name', {ascending: true})
    const {data,error} = await query
    this.firms = data
    console.log(error)
  }
}
</script>

<style scoped>
.firm-name {
  font-size: 9px;
}

.logo {
  max-height: 48px;
}
</style>