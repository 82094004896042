<template>
  <div>
    <div class="main">
      <div class="columns is-centered">
        <div class="column is-8-desktop is-10-tablet about-text">
          <h1 class="title is-1">About the Event</h1>

          <p class="heading is-size-4 has-text-gold">WHAT WE ENVISION</p>
          <p>This school year’s Career Fair takes inspiration from the Filipino term “adhika” which means aspiration, noble ambition, or vision. In line with our theme, we are spearheading this event with the end goal of finding the right opportunities for each member of the student body. The Apprenticeship Program will be the first encounter of law students into the legal profession outside the classroom setting and with that, we are organizing this year’s Career Fair to encourage each student to go after their aspirations and vision of becoming future members of the law profession.</p>

          <p class="heading is-size-4 has-text-gold">THE EVENT</p>
          <p>The Career Fair is an annual event held at the Ateneo Law School to enable law students to prepare for the apprenticeship program and consequently for future employment. The event is organized by the Sophomores with the guidance of the school’s administration and alumni association. It will prepare the students with the necessary skills and tools during the application process and more importantly, connect them with law firms and organizations they may work with in the future.</p>
          <p>This school year, the Career Fair will be held primarily through Zoom in the month of February 2022 from 9:00 am to 12:00 pm. The Career Fair shall comprise of various talks from participating law firms and organizations which will be held on February 21-25. There will also be mock interviews, talks on building a resume, and other activities that will help the attendees prepare for their internship application.</p>
          <p>In lieu of the physical booths due to the COVID-19 restrictions, our team will be launching a website which will consist of various law firms, corporations and organizations. Apart from the website, there will be a week-long program and each law firm, corporation or organization will have the opportunity to introduce their firm and their practice areas to the Ateneo Law School community. Furthermore, they will be able to discuss the internship and career opportunities that they offer. An estimate of 250 law students, lawyers, volunteers, and organizers will be present at the event.</p>

          <p class="heading is-size-4"></p>
        </div>
      </div>

      <h1 class="title is-2 has-text-centered">Featured Firms</h1>
      <FirmLogoWall></FirmLogoWall>

      <div class="has-text-centered">
        <h1 class="title is-1">Our Sponsors</h1>
        <sponsors-list></sponsors-list>
      </div>
    </div>

    <div class="faqs has-background-primary">
      <h1 class="title is-1 has-text-gold">Frequently Asked Questions (FAQs)</h1>
      <p class="subtitle has-text-white">By Marguitta Rodriguez, Third Year Batch Representative</p>
      <faqs-list></faqs-list>
    </div>
  </div>
</template>

<script>
import SponsorsList from "../components/SponsorsList";
import FaqsList from "../components/FaqsList";
import FirmLogoWall from "../components/FirmLogoWall";
export default {
  name: "About",
  components: {FirmLogoWall, FaqsList, SponsorsList},
  title: "About | Adhika: Ateneo Law School Career Fair 2022"
}
</script>

<style scoped>
.main, .faqs {
  padding: 6.9%;
}

.about-text>p {
  margin-bottom: 16px;
}

.heading {
  margin-top: 28px !important;
}
</style>