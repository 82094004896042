<template>
  <div>
    <b-collapse
        v-for="faq in faqs"
        :open="false"
        class="card"
        animation="slide"
        aria-id="contentIdForA11y3">
      <template #trigger="props">
        <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3">
          <p class="card-header-title">
            {{faq.q}}
          </p>
          <a class="card-header-icon">
            <b-icon
                :icon="props.open ? 'minus' : 'plus'">
            </b-icon>
          </a>
        </div>
      </template>

      <div class="card-content">
        <div class="content">
          {{faq.a}}
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "FaqsList",
  data() { return {
    faqs: [
      {
        q: "What should I expect in my internship?",
        a: "The success of your internship will depend on how much you want to learn."
      },
      {
        q: "How do you know which firm to apply to? Should I already know which specialization to pursue?",
        a: "The Career Fair is a great start if you still don’t know which firm you want to apply to. It’s also wise to do your own research on the firms. It’s better if you already know what to pursue so that you can pick a firm which specializes in that particular field but don’t worry if you’re still unsure because the internship experience is a great opportunity to explore."
      },
      {
        q: "When do we usually submit an internship application, given that our internship duration would be from mid June to July?",
        a: "Submit early! Firms accept only a limited number of interns and ALS isn’t the only law school which requires internships. As much as possible, apply to the firms as early as February-March."
      },
      {
        q: "What happens if I am unable to secure an internship?",
        a: "You can always render your hours the following year. However, I would recommend that it be done as soon as you complete your second year because you’ll be busy with your thesis next year."
      },
      {
        q: "How to go about the internship process? What are the steps?",
        a: "Prepare all the documents that you need (CV, Transcript of Records, Cover Letter) then just email the firms that you want to apply to. Usually, they’ll email back with an interview date but some firms don’t host interviews anymore and will immediately offer you a slot."
      },
      {
        q: "What is the time window for the different steps of the internship process?",
        a: "Takes about 2-3 weeks. It really depends on how fast the firm responds."
      },
      {
        q: "How did you prepare? Did you review your first and second year subjects before the internship?",
        a: "No, I don’t think I did anything to prepare because I had no idea what tasks they were gonna make me do. I only reviewed my notes once the tasks were already given."
      },
      {
        q: "How do I maximize my internship?",
        a: "Be proactive. The productivity of your entire internship depends on you. Ask for tasks and finish them promptly so you can ask for more. Ask your supervising lawyer questions when there’s something you don’t understand. Don’t be shy and really make the most out of this opportunity."
      },
      {
        q: "Do firms require interns to report on site?",
        a: "I interned in two different offices. I tried both private and government. In the Public Attorney’s Office, they required purely on site work. In ACCRA, we were mostly work from home but I visited the office a few times. It depends on the firm but this is a question you can ask them during your interview."
      }
    ]
  }}
}
</script>

<style scoped>
.card {
  margin-bottom: 12px;
}
</style>